export interface ProfileAvatarProps {
  setVisible: (value: boolean) => void
  avatar: string
  setShowAvatar: (value: boolean) => void
  onFilePicked: (name: string, value: string) => void
  deleteAvatar: () => void
  readonly?: boolean
}

export const PAGES = {
  security: 'security',
  password: 'password',
  pinCode: 'pinCode',
  phone: 'phone',
  email: 'email',
} as const

export type PageName = (typeof PAGES)[keyof typeof PAGES]

export const TITLES = {
  notifications: 'notifications',
  security: 'security',
  password: 'password',
  pinCode: 'pin_code',
  phone: 'phone',
  email: 'email',
}
