import { translate, utils } from '@pay24/common'
import { MenuItem, Text } from '@ui-kitten/components'
import { observer } from 'mobx-react'
import React, { MutableRefObject, useCallback, useRef } from 'react'
import { useDropzone } from 'react-dropzone'
import { TouchableOpacity, View } from 'react-native'
import placeholderImage from '../../../assets/img/noavatar.png'
import { confirmAlert } from '../alert'
import Dropdown from '../Dropdown'
import Image from '../Image'
import { ProfileAvatarProps } from './types'

const { useTranslation } = translate
const { fileToBase64, getImageUrl } = utils

const dropzoneOptions = {
  accept: { 'image/png': ['.png'], 'image/jpg': ['.jpg', '.jpeg'] },
  maxFiles: 1,
  maxSize: 10 * 1024 * 1024,
  multiple: false,
}

const ProfileAvatarWeb = (props: ProfileAvatarProps) => {
  const {
    setVisible,
    setShowAvatar,
    onFilePicked,
    deleteAvatar,
    readonly,
    avatar,
  } = props
  const { t } = useTranslation()
  const onDrop = useCallback(
    async (files) => {
      if (files && files.length) {
        const file = files[0]
        if (onFilePicked) {
          const bas64String = await fileToBase64(file)
          onFilePicked(bas64String as string, file.name)
        }
      }
    },
    [onFilePicked],
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...dropzoneOptions,
  })
  const ref = useRef() as MutableRefObject<HTMLDivElement>

  return (
    <Dropdown
      placement={'bottom'}
      anchor={
        <TouchableOpacity testID="profileImg" onPress={() => setVisible(true)}>
          <Image
            style={{ width: 100, height: 100, borderRadius: 100 }}
            source={
              avatar
                ? {
                    uri: `${getImageUrl()}/avatar/${avatar}`,
                  }
                : placeholderImage
            }
          />
        </TouchableOpacity>
      }
    >
      {avatar ? (
        <MenuItem
          testID="lookAvatar"
          onPress={() => setShowAvatar(true)}
          title={t('look')}
        />
      ) : null}

      {!readonly ? (
        <>
          <MenuItem
            testID="gallery"
            onPress={() => ref.current.click()}
            style={{ justifyContent: 'flex-start' }}
            title={(prop) => (
              <>
                <div {...getRootProps()} ref={ref}>
                  <input {...getInputProps()} />
                </div>
                <Text {...prop}>{t('gallery')}</Text>
              </>
            )}
          />
          {avatar ? (
            <MenuItem
              testID="deleteAvatar"
              title={t('delete')}
              onPress={() => {
                confirmAlert(
                  t('confirmation'),
                  t('are_you_sure_to_delete'),
                  t('cancel'),
                  t('delete'),
                ).then(() => {
                  deleteAvatar()
                })
              }}
            />
          ) : (
            <View />
          )}
        </>
      ) : null}
    </Dropdown>
  )
}

export default observer(ProfileAvatarWeb)
