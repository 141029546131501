import React, { useCallback } from 'react'
import { utils } from '@pay24/common'
import { useDropzone } from 'react-dropzone'
import { View } from 'react-native'

const { fileToBase64 } = utils

export default ({
  onChange,
  children,
  style,
  onChangeRaw,
  multiple = false,
}) => {
  const onDrop = useCallback(
    async (files) => {
      if (files && files.length) {
        const file = multiple ? files : files[0]
        if (onChange) {
          if (multiple) {
            for (let f of file) {
              const bas64String = await fileToBase64(f)
              onChange(bas64String, f.name)
            }
          } else {
            const bas64String = await fileToBase64(file)
            onChange(bas64String, file.name)
          }
        }
        if (onChangeRaw) {
          onChangeRaw(file)
        }
      }
    },
    [onChange, onChangeRaw],
  )

  const dropzoneOptions = {
    accept: ['image/*'],
    maxFiles: multiple ? 15 : 1,
    maxSize: 10 * 1024 * 1024,
    multiple,
  }
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...dropzoneOptions,
  })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <View style={style}>{children}</View>
    </div>
  )
}
